<template>
  <div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <p>
          <router-link :to="`/send-message/${learnerId}`" class="button">
            Send Message
          </router-link>
        </p>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <h4>Sent messages</h4>
        <ul>
          <li
            v-for="(message, index) in sentMessages"
            :key="`sm_${index}`"
            class="grid-x pad-bottom">
            <ul style="width:100%">
              <li
                v-for="(reply, index) in message.message_replies"
                :key="`rp_${index}`"
                class="grid-x">
                <div class="cell small-3">
                  {{ reply.sender.first_name }} {{ reply.sender.last_name }}
                </div>
                <div class="cell small-5">
                  <div v-html="reply.reply"></div>
                </div>
                <div class="cell small-3">
                  {{ reply.created | tinyDate }}
                </div>
                <div class="cell small-1">
                  <div>
                    <router-link :to="`/view-message/${message.id}`">View</router-link>
                  </div>
                </div>
              </li>
            </ul>
            <div class="cell small-3">
              {{ message.sender.first_name }} {{ message.sender.last_name }}
            </div>
            <div class="cell small-5">
              <div v-html="message.message"></div>
            </div>
            <div class="cell small-3">
              {{ message.created | tinyDate }}
            </div>
            <div class="cell small-1">
              <div>
                <router-link :to="`/view-message/${message.id}`">View</router-link>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <h4>Received messages</h4>
        <ul>
          <li
            v-for="(message, index) in receivedMessages"
            :key="`sm_${index}`"
            class="grid-x pad-bottom">
            <ul style="width:100%">
              <li
                v-for="(reply, index) in message.message_replies"
                :key="`rp_${index}`"
                class="grid-x">
                <div class="cell small-3">
                  {{ reply.sender.first_name }} {{ reply.sender.last_name }}
                </div>
                <div class="cell small-5">
                  <div v-html="reply.reply"></div>
                </div>
                <div class="cell small-3">
                  {{ reply.created | tinyDate }}
                </div>
                <div class="cell small-1">
                  <div>
                    <router-link :to="`/view-message/${message.id}`">View</router-link>
                  </div>
                </div>
              </li>
            </ul>
            <div class="cell small-3">
              {{ message.sender.first_name }} {{ message.sender.last_name }}
            </div>
            <div class="cell small-5">
              <div v-html="message.message"></div>
            </div>
            <div class="cell small-3">
              {{ message.created | tinyDate }}
            </div>
            <div class="cell small-1">
              <div>
                <router-link :to="`/view-message/${message.id}`">View</router-link>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'Messages',
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    learnerId() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      message: '',
      receivedMessages: [],
      sentMessages: [],
      user: {
        email_address: '',
      },
    };
  },
  methods: {
    getMessages() {
      const learnerId = this.$route.params.id;
      axios.get(`/messages/getLearnerMessages/${learnerId}.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}`)
        .then((response) => {
          this.receivedMessages = response.data.messages;
          this.sentMessages = response.data.sentMessages;
        });
    },
  },
  mounted() {
    this.getMessages();
  },
};
</script>
